<template>
<form @submit.prevent="">
    <div class="form-row" v-if="errors.length">
        <div class="col-12">
            <div class="alert alert-danger">
                <strong>خطا های زیر را رفع کنید : </strong>
                <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
        </div>
    </div>


    <div v-if="!isMobile" class="tab-pane fade active show" id="ons-buy" role="tabpanel" aria-labelledby="ons-buy-tab">
        <div class="trading-head">
            <div class="form-row">
                <div class="col-12 col-md-8">
                    <ul class="buy-ul-list">
                        <li>
                            آیتم انتخاب شده : <strong>{{ label }}</strong>
                        </li>
                        <li>
                            قیمت فعلی : <strong>{{ formatPrice(price) }}</strong>
                        </li>
                        <li>
                            واحد ارزش : <strong>{{ type }}</strong>
                        </li>
                        <li>
                            جمع ارزش خرید : <strong>{{ formatPrice(price * count) }}</strong>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <a href="" id="example-number-input5" @click.prevent="openDiagram()" class="w100-w tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <label for="title">تعداد</label>
                    <input type="number" id="title" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="count">
                </div>
            </div>
        </div>
    </div>

    <div v-if="isMobile" class="tab-pane fade active show" id="ons-buy" role="tabpanel" aria-labelledby="ons-buy-tab">
        <div class="form-row">
            <div class="col-12 col-md-12">
                <ul class="buy-ul-list" style="margin-bottom: 0;">
                    <li>
                        آیتم انتخاب شده : <strong>{{ label }}</strong>
                    </li>
                    <li>
                        قیمت فعلی : <strong>{{ formatPrice(price) }}</strong>
                    </li>
                    <li>
                        واحد ارزش : <strong>{{ type }}</strong>
                    </li>
                    <li>
                        جمع ارزش خرید : <strong>{{ formatPrice(price * count) }}</strong>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-row form-row-mobile-flex-modal">
            <div class="col-12">
                <a href="" id="example-number-input5" style="height: 46px;margin-top: 3px;margin-left: 10px;" @click.prevent="openDiagram()" class="w100-w tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
                <v-text-field type="number" class="mobile-app-input mobile-app-input-modal" label="تعداد" id="title" v-model="count"></v-text-field>
            </div>
        </div>
    </div>

    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <div v-else class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="buy" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-success show-widgets-modal"><i class="uil uil-shopping-cart-alt "></i> ثبت سفارش خرید</a>
        </div>
    </div>
</form>
</template>

<style lang="scss">
    #main-header {
        z-index: 100000000;
    }

    #bug-report-modal,
    .modal[role="dialog"] {
        z-index: 999999999;
    }

    #indicator-modal {
        z-index: 2147483647;
    }
</style>

<script>
// این کامپوننت مودال خرید سرمایه گذاری میباشد
export default {
    name: 'InvestmentBuyModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            errors: [],
            symbol: undefined,
            label: '-',
            count: 0,
            price: 0,
            type: '-',
            widgetLoad: true,
            isMobile:false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.loadData();
            this.widgetLoad = false;
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.symbol = undefined;
            this.count = 0;
            this.price = 0;
            this.type = '-';
            this.label = '-',
                this.errors = [];
        },
        // این متد وظیفه هندل کردن درخواست خرید را دارد
        buy() {
            this.errors = [];

            if (this.symbol == undefined) {
                this.errors.push('شاخص مورد نظر را انتخاب نمایید');
            }

            if (parseFloat(this.count) <= 0) {
                this.errors.push('تعداد را وارد کنید');
            }

            if (this.errors.length) {
                return false;
            }

            let api_data = {
                asset: this.symbol,
                count: this.count,
            };

            this.widgetLoad = true;
            this.$helpers.makeRequest('POST', '/investment/buy', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.data.response.status == 'success') {
                    let event = 'success-buy';
                    this.$parent.emitData(event, {}, true);
                }
                this.widgetLoad = false;
            });
        },
        // این متد وظیفه دریافت آخرین قیمت و نرخ شاخص انتخاب شده را دارد
        getPrice(symbol) {
            // ارسال درخواست ای پی آی
            this.widgetLoad = true;
            this.$helpers.makeRequest('GET', '/investment/market-price/' + symbol).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.price = api_response.data.response.price.p;
                this.type = api_response.data.response.type;
                this.widgetLoad = false;
            });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد وظیفه باز کردن مودال شاخصیاب دارد
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                //----------------------
                this.$root.$off(callbackEvent);
                this.label = data.label;
                this.symbol = data.symbol;
                //----------------------
                this.getPrice(data.symbol)

            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
    },
    watch: {
        price: function (val) {
            this.price = val;
        },
    },
}
</script>
